<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import InputGroup from "@/components/forms/input-group";
import ListingTable from "@/components/listings/listing-table";
import StatusGroup from "@/components/forms/status-group";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        ListingTable,
        StatusGroup
    },
    data() {
        return {
            title: "Amenity",
            items: [
                {
                    text: "Amenity"
                },
            ],
            filter: {
                status: 2,
                title: ''
            },
            keyword: '',
            fields: [
                { key: 'id', sortable: true, label: 'ID' },
                { key: "title", sortable: true, label: 'Name' },
                { key: "is_countable", sortable: false, label: 'Is Countable Item' },
                { key: "status", sortable: false, label: 'Status' },
                { key: "action" },
            ],
            selectedCategory: null,
            categoryOptions: [

            ]
        };
    },
    computed: {
        
    },
    watch: {

    },
    async mounted() {
    },
    methods: {
        deleteRecord(id) {
            const deleteCallback = async () => {
                
                let result = await axios.delete(`${process.env.VUE_APP_APIURL}/amenity/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable();
                this.$refs.confirmationModal.closeModal();
                if (result) {
                    if (result.data.code === 'success') {
                        this.$refs.messageModal.showModal('The record has been deleted');
                    } else if(result.data.code === 'permission_denied') {
                        this.$refs.messageModal.showModal('Permission Denied');
                    } else {
                        this.$refs.messageModal.showModal('Internal Error');
                    }
                }
            }
            this.$refs.confirmationModal.showModal(`Confirm delete amenity (ID: ${id})?`, deleteCallback);
        },

        setAmenitiesView(filter) {
            this.filter = Object.assign(this.filter, filter);
        },

        filterTable() {
            const filter = this.filter;
            this.$refs.listingTable.filterTable(filter);
        },

        resetTable() {
            this.filter = {
                title: '',
                status: 2,
            };
            const filter = this.filter;
            this.$refs.listingTable.filterTable(filter);
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body pt-0 pb-0">
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <InputGroup type="text" id="name" displayName="Name" v-model="filter.title" :onEnter="filterTable" />
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3 mt-2">
                                <StatusGroup v-model="filter.status" statusVal="2"/>
                            </div>
                            <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
								<a href="javascript:void(0);" class="btn btn-filter" @click="filterTable">
									Filter
								</a>
								<a href="javascript:void(0);" class="btn btn-orange" style="margin-left: 11px;" @click="resetTable">
									Reset
								</a>
							</div>
                        </div>
                    </div>
                    <div class="pl-4 pr-4">
                        <ListingTable ref="listingTable" :fields="fields" :setAdminView="setAmenitiesView" path="amenity" listName="amenities">
                            <template v-slot:cell(status)="row">
                                <div class="badge font-size-12" :class="{
                                    'badge-soft-danger': row.value == 0,
                                    'badge-soft-success': row.value == 1,
                                }">{{ row.value == 1 ? 'Active' : 'Inactive' }}</div>
                            </template>
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 grey-icon-color" :to="{name: 'Amenity Form', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                    @click="deleteRecord(item.id)">
                                    <i class="ri-delete-bin-line font-size-18"></i>
                                </a>
                            </template>
                            <template #cell(is_countable)="data">
                                {{ data.value === 1 ? "Yes" : "No" }}
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>